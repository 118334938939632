<template>
  <img
    v-if="props.responsive && img.responsive"
    ref="image"
    :loading="loading"
    :srcset="img.responsive.srcset"
    sizes="1px"
    :src="img.url"
    :width="img.responsive.width"
    :height="img.responsive.height"
  />
  <img v-else :loading="loading" :src="img.url" />
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    media: Media;
    conversion?: string;
    responsive?: boolean;
    lazy?: boolean;
  }>(),
  {
    conversion: undefined,
    responsive: false,
    lazy: false,
  }
);

const image = ref(null);

const img = computed(() => {
  if (props.conversion && props.media.conversions && props.media.conversions[props.conversion]) {
    let img = props.media.conversions[props.conversion];

    return {
      url: img.url,
      responsive: img.responsive,
    };
  }

  return {
    url: props.media.full_url,
    responsive: props.media.responsive,
  };
});

const loading = props.lazy ? "lazy" : "eager";

onMounted(() => {
  if (!image.value) return;

  const size = image.value.getBoundingClientRect().width;
  image.value.sizes = Math.ceil((size / window.innerWidth) * 100) + "vw";
});
</script>
